import AuthenticationStore from "@stores/authentication.store";
import CampaignStore from "@stores/cms/campaign.store";
import PostStore from "@stores/cms/post.store";
import OrganizationStore from "@stores/property/organization.store";
import ProjectStore from "@stores/property/project.store";
import PropertyStore from "@stores/property/property.store";
import LocationGroupStore from "@stores/property/location-group.store";
import RevenueStore from "@stores/finance/revenue.store";
import SalaryStore from "@stores/finance/salary.store";
import MasterDataStore from "@stores/system/master-data.store";
import PermissionStore from "@stores/system/permission.store";
import RoleStore from "@stores/system/role.store";
import UserStore from "@stores/system/user.store";
import RequestContactStore from "./cms/request-contact.store";
import CategoryStore from "./cms/category.store";
import MenuStore from "./cms/menu.store";
import OrderStore from "./finance/order.store";

export type RootStoreHydration = {
  permissions?: any;
  authenticationStore?: AuthenticationStore;
};
export class RootStore {
  permissions: any;
  authenticationStore: AuthenticationStore;

  // CMS
  postStore: PostStore;
  categoryStore: CategoryStore;
  campaignStore: CampaignStore;
  requestContactStore: RequestContactStore;
  menuStore: MenuStore;

  // System
  masterDataStore: MasterDataStore;
  userStore: UserStore;
  roleStore: RoleStore;
  permissionStore: PermissionStore;

  // Hr
  salaryStore: SalaryStore;
  revenueStore: RevenueStore;

  // Property
  projectStore: ProjectStore;
  organizationStore: OrganizationStore;
  propertyStore: PropertyStore;
  locationGroupStore: LocationGroupStore;

  // E-commerce
  orderStore: OrderStore;

  constructor() {
    this.authenticationStore = new AuthenticationStore(this);
    this.postStore = new PostStore(this);
    this.categoryStore = new CategoryStore(this);
    this.campaignStore = new CampaignStore(this);
    this.requestContactStore = new RequestContactStore(this);
    this.menuStore = new MenuStore(this);

    // System
    this.masterDataStore = new MasterDataStore(this);
    this.userStore = new UserStore(this);
    this.roleStore = new RoleStore(this);
    this.permissionStore = new PermissionStore(this);

    // HR
    this.salaryStore = new SalaryStore(this);
    this.revenueStore = new RevenueStore(this);

    // Property
    this.propertyStore = new PropertyStore(this);
    this.projectStore = new ProjectStore(this);
    this.organizationStore = new OrganizationStore(this);
    this.locationGroupStore = new LocationGroupStore(this);

    // E-commerce
    this.orderStore = new OrderStore(this);
  }

  hydrate(data: RootStoreHydration) {
    this.permissions = data.permissions;
  }

  isGranted(p?: string) {
    if (!p) return true;
    if (!this.permissions) return false;
    return this.permissions[p];
  }

  isGrantedAny(checkedPermission: string[]) {
    if (!this.permissions) return false;
    return checkedPermission.some((p: string) => this.permissions[p]);
  }
}
